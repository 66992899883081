import * as React from "react";
import Banner from "../Section/Banner";
import device_1Image from "../../images/device_1.png";
import device_2Image from "../../images/device_2.png";
import device_3Image from "../../images/device_3.png";
import device_4Image from "../../images/device_4.png";

export interface IThermenwartungProps {}

export interface IThermenwartungState {}

export default class Thermenwartung extends React.Component<
  IThermenwartungProps,
  IThermenwartungState
> {
  constructor(props: IThermenwartungProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <div>
        <Banner isHome={false} pageName="Thermenwartung" color="white" />
        {/**section 1 */}
        <div className="bg-gray-50 w-full rounded font-[sans-serif] overflow-hidden container my-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-center">
            <div className="lg:col-span-2 py-10 text-center">
              <h1 className="text-gray-800 text-3xl font-bold">
                Thermenwartung Heizwert Kombitherme
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Kombithermen stellen Warmwasser und Heizung gleichzeitig bereit.
                <br />
                Wenn Ihre Therme Brennwerttechnologie verwendet dann bitte hier
                klicken
                <br />
                Wenn Ihr Gasgerät nur Warmwasser bereit stellt, ist es ein
                Durchlauferhitzer
                <br />
              </p>
            </div>
            <img
              src={device_1Image}
              className="w-full h-full object-cover shrink-0"
              alt="device_1"
            />
          </div>
        </div>
        <section>
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">Spar-Wartung</h3>
                      <p className="text-gray-700 mt-2">
                        Nur das Notwendigste wird gemacht
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Mehr Service</strong> als Billigangebote
                          anderer
                        </span>
                        <br />
                        <i className="fa fa-check"></i>{" "}
                        <span className="text-red-600">Keine</span>{" "}
                        10-Minuten-Wartung
                        <br />
                        Kann unter Umständen nicht gemacht werden
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          <strong>Veranschlagte Zeit: ca. 1/2 Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Funktionsprüfung des Abgaswächters{" "}
                          <strong>(rettet Leben)</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          {" "}
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Wasser nachfüllen
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          <strong>Entlüften aller Heizkörper</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">130,- €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">94,- €</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Richtige Wartung
                      </h3>
                      <p className="text-gray-700 mt-2">
                        <i className="fa fa-check text-green-600"></i>{" "}
                        <strong>Komplett</strong> zerlegt,{" "}
                        <strong>gründlich</strong> gereinigt,{" "}
                        <strong>überprüft</strong> und{" "}
                        <strong>neu eingestellt</strong>
                        <br />
                        <i className="fa fa-check text-green-600"></i> Therme
                        ist <strong>Sparsamer</strong> im Gasverbrauch
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Verringert</strong> wesentlich das{" "}
                          <strong>Risiko</strong> eines Kohlenmonoxid-Unfalls*
                        </span>
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>12 Monate Gewährleistung Plus**</strong>
                        </span>
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>Veranschlagte Zeit: ca. 1 Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Funktionsprüfung des Abgaswächters{" "}
                          <strong>(rettet Leben)</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          {" "}
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Wasser nachfüllen
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>Entlüften aller Heizkörper</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Entleerung des Heizungssystems
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>
                            Zerlegung und Säuberung des Wärmeblocks
                            (Lamellenblock)*
                          </strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Prüfung der Einstellungen
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>Optimierung und Nachjustierung</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">197 €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">159€</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**section 2*/}
        <div className="bg-gray-50 w-full rounded font-[sans-serif] overflow-hidden container my-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-center">
            <div className="lg:col-span-2 py-10 text-center">
              <h1 className="text-gray-800 text-3xl font-bold">
                Thermenservice Durchlauferhitzer
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Ein Durchlauferhitzer stellt nur Warmwasser bereit und ist oft
                über oder in der Nähe des Wasserhahnes montiert. <br />
                Wenn Ihre Gastherme auch für die Heizung zuständig ist
              </p>
            </div>
            <img
              src={device_2Image}
              alt="device_2"
              className="w-full h-full object-cover shrink-0"
            />
          </div>
        </div>
        <section>
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">Spar-Wartung</h3>
                      <p className="text-gray-700 mt-2">
                        Nur das Notwendigste wird gemacht
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Mehr Service</strong> als Billigangebote
                          anderer
                        </span>
                        <br />
                        <i className="fa fa-check"></i>{" "}
                        <span className="text-red-600">Keine</span>{" "}
                        10-Minuten-Wartung
                        <br />
                        Kann unter Umständen nicht gemacht werden
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          <strong>Veranschlagte Zeit: ca. 1/2 Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Funktionsprüfung des Abgaswächters{" "}
                          <strong>(rettet Leben)</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          {" "}
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          Reinigung des Abgasrohrs
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">130,- €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">94,- €</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Richtige Wartung
                      </h3>
                      <p className="text-gray-700 mt-2">
                        <i className="fa fa-check text-green-600"></i>{" "}
                        <strong>Komplett</strong> zerlegt,{" "}
                        <strong>gründlich</strong> gereinigt,{" "}
                        <strong>überprüft</strong> und{" "}
                        <strong>neu eingestellt</strong>
                        <br />
                        <i className="fa fa-check text-green-600"></i> Therme
                        ist <strong>Sparsamer</strong> im Gasverbrauch
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Verringert</strong> wesentlich das{" "}
                          <strong>Risiko</strong> eines Kohlenmonoxid-Unfalls*
                        </span>
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>12 Monate Gewährleistung Plus**</strong>
                        </span>
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>Veranschlagte Zeit: ca. ¾ Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          Funktionsprüfung des Abgaswächters{" "}
                          <strong>(rettet Leben)</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          {" "}
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a] text-white">
                          <strong>Entlüften aller Heizkörper</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>
                            Säuberung des Wärmeblocks (Lamellenblock)*
                          </strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          <strong>Optimierung und Nachjustierung</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">197 €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">159€</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**section 3 */}
        <div className="bg-gradient-to-b from-blue-800 via-blue-800 to-blue-400 px-6 sm:py-20 py-10 font-[sans-serif] my-6">
          <div className="max-w-screen-xl mx-auto text-center text-white">
            <h1 className="text-5xl max-sm:text-3xl font-extrabold leading-tight mb-6">
              Haben Sie noch Fragen?
            </h1>
            <p className="text-lg mb-12">
              Wir sind für Sie da. Der Notdienst ist 24/7 erreichbar und hilft
              Ihnen bei allen Fragen rund um Installationen.
            </p>
            <button
              onClick={() => {
                window.location.href = "tel:+436769212103";
              }}
              type="button"
              className="bg-blue-600 text-white text-lg tracking-wide px-8 py-2.5 rounded-full transition duration-300 ease-in-out shadow-lg hover:shadow-xl"
            >
              Rufen Sie uns an
            </button>
          </div>
        </div>
        <section>
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Wartung/Service Brennwertgeräte
                      </h3>
                      <img
                        src={device_1Image}
                        className="w-full h-full object-cover shrink-0"
                        alt="device_1"
                      />
                      <p className="text-gray-700 mt-2">
                        Nur das Notwendigste wird gemacht
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Mehr Service</strong> als Billigangebote
                          anderer
                        </span>
                        <br />
                        <i className="fa fa-check"></i>{" "}
                        <span className="text-red-600">Keine</span>{" "}
                        10-Minuten-Wartung
                        <br />
                        Kann unter Umständen nicht gemacht werden
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          <strong>Veranschlagte Zeit: ca. 1 Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          {" "}
                          <strong>
                            Säuberung des Siphons von Kondenswasser{" "}
                          </strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Säuberung des Brennerraumes{" "}
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Wasser nachfüllen
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          <strong>Entlüften aller Heizkörper</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          <strong>Optimierung und Nachjustierung</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">197,- €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">159,- €</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Wartung/Service Heizkessel
                      </h3>
                      <img
                        src={device_3Image}
                        className="w-full h-full object-cover shrink-0"
                        alt="device_3"
                      />
                      <p className="text-gray-700 mt-2">
                        <i className="fa fa-check text-green-600"></i>{" "}
                        <strong>Komplett</strong> zerlegt,{" "}
                        <strong>gründlich</strong> gereinigt,{" "}
                        <strong>überprüft</strong> und{" "}
                        <strong>neu eingestellt</strong>
                        <br />
                        <i className="fa fa-check text-green-600"></i> Therme
                        ist <strong>Sparsamer</strong> im Gasverbrauch
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Verringert</strong> wesentlich das{" "}
                          <strong>Risiko</strong> eines Kohlenmonoxid-Unfalls*
                        </span>
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>12 Monate Gewährleistung Plus**</strong>
                        </span>
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-orange-100">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a]  text-white">
                          <strong>Veranschlagte Zeit: ca. 1 Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          {" "}
                          <strong>
                            {" "}
                            Säuberung des Siphons von Kondenswasser{" "}
                          </strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a]  text-white">
                          {" "}
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a]  text-white">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100  text-white">
                          Säuberung des Brennerraumes{" "}
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a]  text-white">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100">
                          Wasser nachfüllen
                        </li>
                        <li className="border-1 border-black p-2 bg-[#27347a]  text-white">
                          <strong>Entlüften aller Heizkörper</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-orange-100  text-white">
                          <strong>Optimierung und Nachjustierung</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">197 €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">159€</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**section 4 */}
        <div className="bg-gray-50 w-full rounded font-[sans-serif] overflow-hidden container my-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-center">
            <div className="lg:col-span-2 py-10 text-center">
              <h1 className="text-gray-800 text-3xl font-bold">
                Thermenwartung Heizwert Kombitherme
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Kombithermen stellen Warmwasser und Heizung gleichzeitig bereit.
                <br />
                Wenn Ihre Therme Brennwerttechnologie verwendet dann bitte hier
                klicken
                <br />
                Wenn Ihr Gasgerät nur Warmwasser bereit stellt, ist es ein
                Durchlauferhitzer
                <br />
              </p>
            </div>
            <img
              src={device_4Image}
              className="w-full h-full object-cover shrink-0"
              alt="device_4"
            />
          </div>
        </div>
        <section>
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Gaskonvektor Wartung
                      </h3>
                      <p className="text-gray-700 mt-2">
                        Vollständige gründliche Wartung
                        <br />
                        <span className="text-green-600">
                          <i className="fa fa-check"></i>{" "}
                          <strong>Mehr Service</strong> 12 Monate Gewährleistung
                        </span>
                        <br />
                      </p>
                    </div>
                    <div className="text-center">
                      <ul className="space-y-2">
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Wegzeit
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          <strong>Veranschlagte Zeit: ca. 1/2 Stunde</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Funktionsprüfung des Abgaswächters{" "}
                          <strong>(rettet Leben)</strong>
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          {" "}
                          Säuberung des Brenners
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          {" "}
                          Säuberung der Strömungssicherung
                        </li>
                        <li className="border-1 border-black p-2 bg-[#f28422]">
                          Reinigung des Abgasrohrs
                        </li>
                        <li className="border-1 border-black p-2 bg-blue-100">
                          Entlüften aller Heizkörper
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-lg">
                        <span className="text-red-600">Aktion:</span> Statt{" "}
                        <span className="line-through">130,- €</span>
                      </p>
                      <p className="text-black text-2xl font-bold">94,- €</p>
                      <p className="text-gray-600">inkl. MwSt. (Wien)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-6">
                <div className="w-full mb-5">
                  <div className="border p-6 text-center bg-white shadow-md rounded-lg">
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Abgasmessung mit Prüfbefund
                      </h3>
                      <p className="text-gray-700 mt-2">
                        Thermenwartung Wien: Als von der Stadt Wien bestätigtes
                        Prüforgan führen wir auch die gesetzlich verpflichtende
                        Abgasmessung durch. Diese ist bei Thermen unter 26kW
                        Leistung alle 4 Jahre durchzuführen und kostet bei uns
                        im Zuge einer Thermenwartung nur 50 Euro (inkl. MwSt.).
                        Sie erhalten den Prüfbefund/Befundbericht zur
                        Weiterleitung an Ihren Rauchfangkehrer und die Plakette.
                      </p>
                    </div>
                    <div className="mb-4">
                      <h3 className="text-2xl font-semibold">
                        Thermen-Reparatur und Heizungs-Notdienst
                      </h3>
                      <p className="text-gray-700 mt-2">
                        Fachgerechte Reparatur durch einen auf Ihre
                        Gasheizung/Durchlauferhitzer spezialisierten Monteur.
                        Unser firmeneigener Notdienst kümmert sich nachts, am
                        Wochenende und an Feiertagen um Ihre Heizungsstörung.
                        <br />
                        <span className="text-red-600 font-bold text-2xl">
                          <a href="tel:+436602265474"> +43 660 2265474</a>
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
